import React from 'react';

function About() {
  return (
    <div class="container">
      <p class="content">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum. Donec in efficitur leo. Morbi commodo rhoncus mi nec pharetra. Aliquam erat volutpat. Nulla facilisi. Sed ornare, nunc in lobortis cursus, nulla diam varius arcu, in accumsan lorem risus eu nisl. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi volutpat commodo dolor, sed feugiat nibh posuere non. Mauris lacinia sapien vel diam ultricies, vel euismod libero laoreet. Mauris varius viverra orci, a aliquet justo. 
      </p>
    </div>
  );
}

export default About;
