import React, { useEffect, useState } from 'react';
import { db } from '../index';  // Adjust the path as necessary
import { query, collection, getDocs, limit } from 'firebase/firestore/lite';

function Food() {
  const [entry, setEntry] = useState(null);

  // Hard-coded URL
  const imageUrl = "https://firebasestorage.googleapis.com/v0/b/il-paring-knife.appspot.com/o/DSCF7354-resized.jpg?alt=media&token=b6e57776-9e50-463a-aae6-1804b5f1bf56";

  useEffect(() => {
    const getEntry = async () => {
      try {
        const querySnapshot = await getDocs(query(collection(db, 'entries'), limit(1)));

        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          setEntry(doc.data());
        });
      } catch (err) {
        console.error('Error fetching entries:', err);
      }
    };

    getEntry();
  }, []);

  if (entry) {
    return (
      <div>
        <h1>{entry.content}</h1>
        <img 
          src={imageUrl} 
          alt="Your Image Description" 
          style={{ width: '60%' }}
        />
        <p >{entry.title}</p>
      </div>
    );
  }

  return <div>Loading...</div>;
}

export default Food;
